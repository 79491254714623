import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Col, Container, Row } from "react-bootstrap";
export const DescriptionPageTemplate = ({
  title,
  pitch,
  image,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  const imgSrc = image ? image.childImageSharp.fluid.src : "";
  const fullImg = imgSrc && !pitch;
  return (
    <Container className="mt-5">
      <h2>{title}</h2>
      {pitch && (
        <Row className="m-0 mt-5 mb-5">
          <Col xs={false} md={7}
            style={{
              height: "45vh",
              backgroundPositionX: "right",
              backgroundSize: "cover",
              clipPath: "polygon(0 0, 100% 0%, 75% 100%, 0% 100%)",
              backgroundImage: `url(${imgSrc})`,
            }}
          ></Col>
          <Col  xs={12} md={4} className="m-2" style={{ alignSelf: "center" }}>
            <div className="my-auto" style={{ fontSize: "1.5em" }}>
              {pitch}
            </div>
          </Col>
        </Row>
      )}

      <PageContent className="content" content={content} />
    </Container>
  );
};

DescriptionPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  pitch: PropTypes.string,
  path: PropTypes.string,
  language: PropTypes.string,
  image: PropTypes.object,
};

const DescriptionPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout path={post.frontmatter.path} language={post.frontmatter.language}>
      <DescriptionPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        pitch={post.frontmatter.pitch}
        language={post.frontmatter.language}
        content={post.html}
      />
    </Layout>
  );
};

DescriptionPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DescriptionPage;

export const descriptionPageQuery = graphql`
  query DescriptionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        pitch
        language
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
